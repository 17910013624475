import { Action } from '@ngrx/store';

export enum CoreActionTypes {
  CoreContentRequested = '[Core] Content Requested',
  CoreContentLoaded = '[Core] Content Loaded',
  CoreToggleMenu = '[Core] Toggle Menu',
  CoreFetchError = '[Core] Fetch Error'
}

export class CoreContentsRequestedAction implements Action {
  readonly type = CoreActionTypes.CoreContentRequested;
}

export class CoreContentsLoadedAction implements Action {
  readonly type = CoreActionTypes.CoreContentLoaded;
  constructor(public payload: { data }) {}
}

export class CoreToggleMenuAction implements Action {
  readonly type = CoreActionTypes.CoreToggleMenu;
  constructor(public payload: { menuOpen: boolean }) {}
}

export class CoreFetchErrorAction implements Action {
  readonly type = CoreActionTypes.CoreFetchError;
  constructor(public payload: { error: any }) {}
}

export type CoreActions =
  | CoreContentsRequestedAction
  | CoreContentsLoadedAction
  | CoreToggleMenuAction
  | CoreFetchErrorAction;
