import { Component, Input, OnInit } from '@angular/core';

import { ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Menu } from './models/core.model';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromCore from './store/core.actions';
import { AppState } from 'src/app/reducers';

import { selectMenu, selectLogo } from './store/core.selectors';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  menu$: Observable<Menu[] | null>;
  logo$: Observable<string>;
  navEnd: Observable<NavigationEnd>;
  homePage = null;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private store: Store<AppState>,
    private router: Router,
  ) {
    this.initializeApp();
    this.navEnd = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
  }

  ngOnInit() {
    this.store.dispatch(new fromCore.CoreContentsRequestedAction());
    this.menu$ = this.store.pipe(
      select(selectMenu),
    );
    this.logo$ = this.store.pipe(
      select(selectLogo),
    );
    this.navEnd.subscribe(evt => {
      this.homePage = evt.url;
    });

    /* setTimeout(() => {
      if(this.homePage == '/') {
        this.router.navigateByUrl('/contact');
      }
    }, 6000); */
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
