import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { storeFreeze } from 'ngrx-store-freeze';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import * as fromCore from '../store/core.reducer';
import { CoreState } from '../store/core.state';

export interface AppState {
  core: CoreState;
  router: RouterReducerState;
}

export const reducers: ActionReducerMap<AppState> = {
  core: fromCore.coreReducer,
  router: routerReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [storeFreeze] : [];