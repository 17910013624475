import { createSelector, createFeatureSelector } from '@ngrx/store';
import { CoreState } from './core.state';

export const selectCoreState = createFeatureSelector<CoreState>('core');

export const selectMenu = createSelector(
  selectCoreState,
  CoreState => CoreState.configuration.Menu
);

export const selectLogo = createSelector(
  selectCoreState,
  CoreState => CoreState.configuration.Logo.url
);

export const selectGoogleMapLogo = createSelector(
  selectCoreState,
  CoreState => CoreState.configuration.GoogleMapLogo.url
);

export const selectGoogleMap = createSelector(
  selectCoreState,
  CoreState => CoreState.configuration.GoogleMap.coordinates
);

export const selectLandingSlider = createSelector(
  selectCoreState,
  CoreState => CoreState.configuration.Background
);

export const selectMenuStatus = createSelector(
  selectCoreState,
  CoreState => CoreState.menuOpen
);

export const selectEbrochure = createSelector(
  selectCoreState,
  CoreState => CoreState.configuration.Ebrochure.url
);

export const coreContentsLoaded = createSelector(
  selectCoreState,
  CoreState => CoreState.coreContentsLoaded
);

export const getIsLoading = createSelector(
  selectCoreState,
  CoreState => CoreState.isLoading
);

export const getError = createSelector(
  selectCoreState,
  CoreState => CoreState.error
);