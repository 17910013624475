import { Component, OnInit, ViewChild, Input, AfterViewInit, ElementRef, Renderer2 } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { Router } from '@angular/router';
import { Menu } from '../../../models/core.model';
import { selectMenuStatus, selectEbrochure } from '../../../store/core.selectors';
import * as fromCore from '../../../store/core.actions';
import { ModalController } from '@ionic/angular';
import { ModalComponent } from '../../../shared/components/modal/modal.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterViewInit {
  @Input() menuList;
  toggleFlag: boolean = false;
  whichPage = null;
  pdfSrc: String;

  constructor(private router:Router, private renderer: Renderer2, private store: Store<AppState>, public modalController: ModalController) { }

  @ViewChild("navbtn", {static: false}) navbtn: ElementRef;
  @ViewChild("nav_modal", {static: false}) nav_modal: ElementRef;
  @ViewChild("nav_mobile", {static: false}) nav_mobile: ElementRef;

  ngOnInit() {}

  ngAfterViewInit() {
    this.store.pipe(
      select(selectMenuStatus),
    ).subscribe(status => this.toggleMenu(status));

    this.store.pipe(
      select(selectEbrochure),
    ).subscribe(src => this.pdfSrc = src);

    this.renderer.listen(this.navbtn.nativeElement, 'click', () => {
      this.store.dispatch(new fromCore.CoreToggleMenuAction({menuOpen: this.toggleFlag}));
    });

    this.renderer.listen(this.nav_modal.nativeElement, 'click', (event) => {
      this.store.dispatch(new fromCore.CoreToggleMenuAction({menuOpen: false}));
      if (event.target && event.target.nodeName == "LI") {
        this.whichPage = event.target.dataset.href;
        if(this.whichPage == 'ebrochure') {
          //this.presentModal('ebrochure', 'pdfviewer');
          window.open('https://vmwgroup.com.sg/ksc/brochure/mobile/index.html', '_blank');
        } else if (this.whichPage == 'specifications') {
          //this.presentModal('specifications', 'pdfviewer');
          window.open('assets/specifications/index.html', '_blank');
        } else {
          this.router.navigateByUrl('/'+this.whichPage);
        }
      } else if(event.target && event.target.nodeName == "IMG") {
        this.router.navigateByUrl('/about-us');
      }
    });

    this.renderer.listen(this.nav_mobile.nativeElement, 'click', (event) => {
      console.log(event.target.nodeName);
      if (event.target && event.target.nodeName == "LI") {
        this.store.dispatch(new fromCore.CoreToggleMenuAction({menuOpen: false}));
        this.whichPage = event.target.dataset.href;
        if(this.whichPage == 'ebrochure') {
          //this.presentModal('ebrochure', 'pdfviewer');
          window.open('assets/brochure/index.html', '_blank');
        } else if (this.whichPage == 'specifications') {
          //this.presentModal('specifications', 'pdfviewer');
          window.open('assets/specifications/index.html', '_blank');
        } else {
          this.router.navigateByUrl('/'+this.whichPage);
        }
      } else if (event.target && event.target.nodeName == "DIV") {
        if(event.target.nextSibling.classList.contains('shown')){
          event.target.nextSibling.classList.remove('shown');
        } else {
          event.target.nextSibling.classList.add('shown');
        }
      }
    });
  }

  toggleMenu(status) {
    if (status) {
      this.renderer.addClass(this.navbtn.nativeElement.children[0], 'active');
      this.renderer.addClass(this.nav_mobile.nativeElement, 'shown');
    } else {
      this.renderer.removeClass(this.navbtn.nativeElement.children[0], 'active');
      this.renderer.removeClass(this.nav_mobile.nativeElement, 'shown');
    }
    this.toggleFlag = (this.toggleFlag === true)? false : true;
  }

  async presentModal(pdfsrc, mediatype) {
    const modal = await this.modalController.create({
      component: ModalComponent,
      cssClass: 'modal-fullscreen-'+mediatype,
      componentProps: {
        'details': pdfsrc,
        'mediatype': mediatype,
      }
    });
    return await modal.present();
  }
}