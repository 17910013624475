import { coreInitialState, CoreState } from './core.state';
import { CoreActions, CoreActionTypes } from './core.actions';

export function coreReducer(state = coreInitialState, action: CoreActions): CoreState {
  switch (action.type) {

    case CoreActionTypes.CoreContentRequested:
      return {...state, isLoading:true};

    case CoreActionTypes.CoreContentLoaded:
      return {...state, configuration: action.payload, isLoading:false, coreContentsLoaded:true};

    case CoreActionTypes.CoreToggleMenu:
      return {...state, menuOpen: action.payload.menuOpen};

    case CoreActionTypes.CoreFetchError:
      return {...state, isLoading:false};

    default:{
      return state;
    }
  }
}