import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from "rxjs/operators";
import data from '../../../assets/response.json';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private dataStore = new BehaviorSubject(data);
  allData: Observable<any> = this.dataStore.asObservable();

  constructor() { }

  getData(filter): Observable<any> {
    switch (filter) {
      case 'core':
        return this.allData.pipe(map(data => data[0].Configuration))
      case 'home':
        return this.allData.pipe(map(data => data[0].Home))
      case 'design':
        return this.allData.pipe(map(data => data[0].Design))
      case 'location':
        return this.allData.pipe(map(data => data[0].Location))
      case 'facilities':
        return this.allData.pipe(map(data => data[0].Facilities))
      case 'floorplan':
        return this.allData.pipe(map(data => data[0].Floorplans))
      case 'brand':
        return this.allData.pipe(map(data => data[0].Brand))
      case 'gallery':
        return this.allData.pipe(map(data => data[0].Gallery))
      case 'contact':
          return this.allData.pipe(map(data => data[0].Contact))
    }
  }
}