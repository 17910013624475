import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, AfterViewInit {
  @Input() details;
  @Input() mediatype;
  dismissData = null;
  collapsing = true;
  pointerEvents = false;
  ZOOM_STEP:number = 0.25;
  DEFAULT_ZOOM:number = 1;
  pdfZoom = this.DEFAULT_ZOOM;

  @ViewChild('highzone', {static: false }) highzone: ElementRef;
  @ViewChild('highzonebutton', {static: false }) highzonebutton: ElementRef;
  @ViewChild('lowzone', {static: false }) lowzone: ElementRef;
  @ViewChild('lowzonebutton', {static: false }) lowzonebutton: ElementRef;
  @ViewChild('l33', {static: false }) l33: ElementRef;
  @ViewChild('l33button', {static: false }) l33button: ElementRef;
  @ViewChild('slides', {static: false}) slides: IonSlides;
  @ViewChild('prevarrow', { static: false }) private prevarrow: ElementRef;
  @ViewChild('nextarrow', { static: false }) private nextarrow: ElementRef;

  slidesOptions = {
    initialSlide: 0,
    zoom: false,
    autoplay: false,
    effect: 'slide',
    autoHeight: false
  };

  constructor(
    public modalController: ModalController,
    private renderer: Renderer2)
  {
    window.addEventListener("message", this.getMessage.bind(this), false);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  slideChanged(e) {
    console.log(e);
    this.prevarrow.nativeElement.classList.remove('disabled');
    this.nextarrow.nativeElement.classList.remove('disabled');

    if(e.target.swiper.isBeginning){
      this.prevarrow.nativeElement.classList.add('disabled');
      this.nextarrow.nativeElement.classList.remove('disabled');
    }
    if(e.target.swiper.isEnd){
      this.prevarrow.nativeElement.classList.remove('disabled');
      this.nextarrow.nativeElement.classList.add('disabled');
    }
  }

  slidePrev() {
    this.slides.slidePrev();
  }

  slideNext() {
    this.slides.slideNext();
  }

  zoomIn() {
		this.pdfZoom += this.ZOOM_STEP;
	}

	zoomOut() {
		//if (this.pdfZoom > this.DEFAULT_ZOOM) {
			this.pdfZoom -= this.ZOOM_STEP;
		//}
	}

  openL33() {
    this.l33.nativeElement.src = 'assets/172m/index.html' ;
    this.l33.nativeElement.style.pointerEvents = 'all';
    this.l33button.nativeElement.style.pointerEvents = 'all';
  }

  getMessage(message: any) {
		if(message.data.name != undefined) {
      if(message.data.name.includes('high')) {
        this.highzone.nativeElement.src = 'assets/'+ message.data.name + '/index.html?status='+ message.data.status ;
        this.highzone.nativeElement.style.pointerEvents = 'all';
        this.highzonebutton.nativeElement.style.pointerEvents = 'all';
      } else {
        this.lowzone.nativeElement.src = 'assets/'+ message.data.name + '/index.html';
        this.lowzone.nativeElement.style.pointerEvents = 'all';
        this.lowzonebutton.nativeElement.style.pointerEvents = 'all';
      }
		}
	}

  dismisslowzoneoverlay() {
    this.lowzone.nativeElement.src = '';
    this.lowzone.nativeElement.style.pointerEvents = 'none';
    this.lowzonebutton.nativeElement.style.pointerEvents = 'none';
  }

  dismissl33() {
    this.l33.nativeElement.src = '';
    this.l33.nativeElement.style.pointerEvents = 'none';
    this.l33button.nativeElement.style.pointerEvents = 'none';
  }

  dismisshighzoneoverlay() {
    this.highzone.nativeElement.src = '';
    this.highzone.nativeElement.style.pointerEvents = 'none';
    this.highzonebutton.nativeElement.style.pointerEvents = 'none';
  }

  dismiss() {
    this.modalController.dismiss(this.dismissData);
  }
}
