import { ContentChild, ContentChildren, Directive, Input, QueryList } from "@angular/core";
import { AccordionContent } from "./accordion-content.directives";
import { AccordionHeader } from "./accordion-header.directives";
import { AccordionTitle } from "./accordion-title.directives";

@Directive({
  selector: "accordion-item"
})
export class AccordionItem {
  @Input() title = "";
  @Input() disabled = false;
  @ContentChild(AccordionContent, {static: false, read: AccordionContent}) content: AccordionContent;
  @ContentChild(AccordionTitle, {static: false, read: AccordionContent}) customTitle: AccordionContent;
  @ContentChild(AccordionHeader, {static: false, read: AccordionContent}) customHeader: AccordionContent;
}
