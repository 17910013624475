export interface CoreState {
    configuration: any | null;
    isLoading: boolean;
    menuOpen: boolean;
    coreContentsLoaded: boolean;
    error: any;
}

export const coreInitialState: CoreState = {
    configuration: null,
    isLoading: true,
    menuOpen: false,
    coreContentsLoaded: false,
    error: null,
};
