import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distinct'
})
export class DistinctPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let uniqueArray = value.map(item => item.type).filter((el, index, array) => {
      return array.indexOf(el) === index;
    });

    return uniqueArray;
  }
}
