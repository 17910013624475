import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DataService } from '../shared/services/data.service';
import { CoreActionTypes, CoreContentsRequestedAction, CoreContentsLoadedAction,  } from './core.actions';
import { filter, map, mergeMap, withLatestFrom} from "rxjs/operators";
import { Store, select } from '@ngrx/store';
import { AppState } from '../reducers/index';
import { coreContentsLoaded } from './core.selectors';


@Injectable()
export class CoreEffects {

  constructor(private actions$: Actions, private coreService: DataService, private store: Store<AppState>) {}

  @Effect()
  selectCoreContents$ = this.actions$
    .pipe(
      ofType<CoreContentsRequestedAction>(CoreActionTypes.CoreContentRequested),
      withLatestFrom(this.store.pipe(select(coreContentsLoaded))),
      filter(([action, coreContentsLoaded]) => !coreContentsLoaded),
      mergeMap(() => this.coreService.getData('core')),
      map(configuration => new CoreContentsLoadedAction(configuration))
  );
}