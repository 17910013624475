import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: 'landing', loadChildren: './views/landing/landing.module#LandingPageModule' },
  { path: 'location', loadChildren: './views/location/location.module#LocationPageModule' },
  { path: 'about-us', loadChildren: './views/aboutus/aboutus.module#AboutusPageModule' },
  { path: 'overview', loadChildren: './views/overview/overview.module#OverviewPageModule' },
  { path: 'overview/offices', loadChildren: './views/offices/offices.module#OfficesPageModule' },
  { path: 'overview/amenities', loadChildren: './views/amenities/amenities.module#AmenitiesPageModule' },
  { path: 'layout-plans', loadChildren: './views/layoutplans/layoutplans.module#LayoutplansPageModule' },
  { path: 'sustainability', loadChildren: './views/sustainability/sustainability.module#SustainabilityPageModule' },
  { path: 'specifications', loadChildren: './views/specifications/specifications.module#SpecificationsPageModule' },
  { path: 'smart-technology', loadChildren: './views/smarttechnology/smarttechnology.module#SmarttechnologyPageModule' },
  { path: 'credentials', loadChildren: './views/credentials/credentials.module#CredentialsPageModule' },
  { path: 'perspectives-videos', loadChildren: './views/videosimages/videosimages.module#VideosImagesPageModule' },
  { path: 'contact', loadChildren: './views/contact/contact.module#ContactPageModule' },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor() {}
}
