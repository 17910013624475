import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ModalComponent } from './components/modal/modal.component';
import { DistinctPipe } from './pipes/distinct.pipe';
import { IonicModule } from '@ionic/angular';
import { SafePipe } from './pipes/safe.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AccordionModule } from './components/accordion/accordion.module';
import { RecaptchaModule } from 'ng-recaptcha';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        PdfViewerModule,
        AccordionModule,
        RecaptchaModule
    ],
    declarations: [
        NavbarComponent,
        ModalComponent,
        DistinctPipe,
        SafePipe,
    ],
    entryComponents: [ModalComponent],
    exports: [
        NavbarComponent,
        AccordionModule,
        DistinctPipe,
        SafePipe,
        RecaptchaModule,
    ]
  })

export class SharedModule {}